import React from 'react'
import PropTypes from 'prop-types'
import BlockText from 'components/ui/block-text'
import ValorComponente from 'components/valorComponent'

const Valores = ({
  title,
  description,
  enableEncabezado,
  objectValores,
  rawValores,
}) => {
  return (
    <div className="container valores">
      {enableEncabezado && (
        <div className="encabezado col-10 mx-auto">
          <h2 className="encabezado-title text-center">{title}</h2>
          <div className="encabezado-description col-10 mx-auto text-center">
            <BlockText data={description} />
          </div>
        </div>
      )}
      <div className="d-flex flex-wrap my-5">
        {rawValores.map(({ titulo }, key) => (
          <div className="item col-10 col-lg-6 mx-auto">
            <ValorComponente
              image={objectValores[key]?.image?.image?.asset?.url}
              alt={objectValores[key]?.image?.alt}
              title={titulo}
              paragraph={objectValores[key]?._rawDescription}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

Valores.propTypes = {
  img1: PropTypes.string.isRequired,
  img2: PropTypes.string.isRequired,
  img3: PropTypes.string.isRequired,
  img4: PropTypes.string.isRequired,
  img5: PropTypes.string.isRequired,
  img6: PropTypes.string.isRequired,
}

export default Valores
