import React from 'react'
import PropTypes from 'prop-types'

import ImgPrincipal from 'components/componenteImagenes/ImagePrincipal'
import GrupoImagenesPrincipal from 'components/componenteImagenes/grupoImagenes'
import BlockText from 'components/ui/block-text'
import { navigate } from 'gatsby'

const Services = ({
  title,
  paragraph,
  enableEncabezado,
  rawServicios,
  objetoServicios,
}) => {
  return (
    <div className="container services">
      {enableEncabezado && (
        <div className="encabezado col-10 mx-auto">
          <h2 className="encabezado-title text-center my-4">{title}</h2>
          <div className="encabezado-description col-10 mx-auto text-center">
            <BlockText data={paragraph} />
          </div>
        </div>
      )}
      {rawServicios.map(({ titleOne }, key) => (
        <div className="services-items d-flex flex-column flex-lg-row justify-content-between mt-5">
          <div className="services-items--images d-flex flex-column align-items-center col-lg-7 mb-lg-5">
            <div className="imgPrincipal col-12 p-3">
              <ImgPrincipal
                imgPrincipal={
                  objetoServicios[key]?.ImagenPrincipal?.image?.image?.asset
                    ?.url
                }
                title={objetoServicios[key]?.ImagenPrincipal?.title}
              />
            </div>
            <div className="groupImages d-flex flex-wrap col-12">
              {objetoServicios[key]?.galeria?.enable && (
                <GrupoImagenesPrincipal
                  rawGaleria={objetoServicios[key]?._rawGaleria}
                  rawImages={objetoServicios[key]?._rawGaleria?.images}
                  objetoImagenes={objetoServicios[key]?.galeria}
                  publicacion={objetoServicios[key]}
                />
              )}
            </div>
          </div>
          <div className="services-items--text col-12 col-lg-4 p-3 p-lg-0 mb-5">
            <div className="listas-title">
              <h4>{titleOne}</h4>
            </div>
            <div className="listas my-1">
              <BlockText data={objetoServicios[key]?._rawDescription} />
            </div>
            <div className="d-flex justify-content-center justify-content-lg-start">
              {objetoServicios[key]?.cta?.enable && (
                <button
                  className="hero-text--button btn col-11  py-3 py-lg-2 my-2 mx-auto"
                  onClick={() => {
                    navigate(`/${objetoServicios[key]?.cta?.link}`)
                  }}
                >
                  {objetoServicios[key]?.cta?.text}
                </button>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
Services.propTypes = {
  imgPrincipal: PropTypes.string.isRequired,
  img1: PropTypes.string.isRequired,
  img2: PropTypes.string.isRequired,
  img3: PropTypes.string.isRequired,
  img4: PropTypes.string.isRequired,
  img5: PropTypes.string.isRequired,
  img6: PropTypes.string.isRequired,
  imgSecond: PropTypes.string.isRequired,
  imgSec1: PropTypes.string.isRequired,
  imgSec2: PropTypes.string.isRequired,
  imgSec3: PropTypes.string.isRequired,
  description1: PropTypes.any.isRequired,
  description2: PropTypes.any.isRequired,
}

export default Services
