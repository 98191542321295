import React from 'react'

import Layout from 'components/layout'
import HeroComponent from 'components/hero'
import ValoresSection from 'components/valores'
import CallToAction from 'components/callToAction'
import Services from 'components/services'
import Seo from 'components/seo'

import { graphql } from 'gatsby'

const IndexPage = ({ data, location }) => {
  const {
    sanityPage: {
      tabs: { seo, Call_to_Action, Hero, Valores, Services_Home },
    },
    sanityAppereance: {
      tabsAppereance: { header, footer },
    },
  } = data

  return (
    <Layout header={header} footer={footer}>
      {seo?.seo?.focus_keyword && seo?.author && seo?.seo?.focus_synonyms && (
        <Seo data={seo} />
      )}
      {Hero?.enable && (
        <HeroComponent
          navbar={Hero?.logoEmpresaHeader?.image?.asset?.url}
          navbarAlt={Hero?.logoEmpresaHeader?.alt}
          imageBackground={Hero?.image.image.asset.url}
          title={Hero?.title}
          subTitle={Hero?.description}
          btnText={Hero?.cta?.text}
          btnEnable={Hero?.cta?.enable}
          btnLink={Hero?.cta?.link}
          location={location.pathname}
        />
      )}
      {Valores?.enable && (
        <ValoresSection
          title={Valores?.encabezadoMain?.text}
          description={Valores?.encabezadoMain?._rawDescription}
          enableEncabezado={Valores?.encabezadoMain?.enable}
          rawValores={Valores?._rawValores}
          objectValores={Valores?.Valores}
        />
      )}
      {Call_to_Action?.enable && (
        <CallToAction
          title={Call_to_Action?.title}
          paragraph={Call_to_Action?.description}
          btnText={Call_to_Action?.cta.text}
          btnEnable={Call_to_Action?.cta.enable}
          btnLink={Hero?.cta?.link}
        />
      )}
      {Services_Home?.enable && (
        <Services
          title={Services_Home?.encabezadoMain?.text}
          paragraph={Services_Home?.encabezadoMain?._rawDescription}
          enableEncabezado={Services_Home?.encabezadoMain?.enable}
          rawServicios={Services_Home?._rawServicios}
          objetoServicios={Services_Home?.servicios}
        />
      )}
    </Layout>
  )
}

export const IndexQuery = graphql`
  query HomeQuery {
    sanityPage(slug: { current: { eq: "home-page" } }) {
      tabs {
        seo {
          author
          _key
          seo {
            seo_title
            meta_description
            focus_synonyms
            focus_keyword
            _type
            _key
          }
          ogImage {
            asset {
              url
            }
          }
        }
        Hero {
          title
          description
          enable
          cta {
            link
            text
            enable
          }
          image {
            alt
            image {
              asset {
                url
                title
              }
            }
          }
          logoEmpresaHeader {
            image {
              asset {
                url
              }
            }
            alt
          }
        }
        Call_to_Action {
          description
          enable
          title
          cta {
            enable
            link
            text
          }
        }
        Valores {
          _rawValores
          enable
          encabezadoMain {
            _rawDescription
            text
            enable
          }
          Valores {
            _rawDescription
            image {
              alt
              image {
                asset {
                  url
                }
              }
            }
          }
        }
        Services_Home {
          enable
          _rawServicios
          encabezadoMain {
            text
            enable
            _rawDescription
          }
          servicios {
            titleOne
            _rawGaleria
            _rawDescription
            ImagenPrincipal {
              title
              image {
                alt
                image {
                  asset {
                    url
                  }
                }
              }
            }
            cta {
              enable
              link
              text
            }
            galeria {
              enable
              images {
                alt
                image {
                  asset {
                    url
                  }
                }
                _rawImage
              }
            }
          }
        }
      }
    }
    sanityAppereance(slug: { current: { eq: "apariencia" } }) {
      tabsAppereance {
        footer {
          correo1
          correo2
          correo3
          departamento
          direccion
          enableFooter
          footerParagrah
          footerTitle
          logoEmpresaFooter {
            alt
            image {
              asset {
                url
              }
            }
          }
        }
        header {
          correo
          enableHeader
          linkFacebook
          linkInstagram
          linkLinkedin
          linkTwitter
          messenger
          telefono
        }
      }
    }
  }
`

export default IndexPage
