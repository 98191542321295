import React from 'react'
import BlockText from 'components/ui/block-text'

const ValorComponente = ({ image, alt, title, paragraph }) => {
  return (
    <div className="item-info d-flex flex-column flex-lg-row align-items-center col-lg-11 mt-0 mb-5 my-lg-5">
      <div className="item-info--img ">
        <img src={image} alt={alt} />
      </div>
      <div className="item-info--text text-center text-lg-start me-4 ms-5">
        <h4 className="item-info--text--h4">{title}</h4>
        <BlockText data={paragraph} />
      </div>
    </div>
  )
}

export default ValorComponente
